// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-articles-article-js": () => import("./../../../src/articles/article.js" /* webpackChunkName: "component---src-articles-article-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lorem-ipsum-js": () => import("./../../../src/pages/lorem-ipsum.js" /* webpackChunkName: "component---src-pages-lorem-ipsum-js" */),
  "component---src-pages-reading-the-emoji-in-the-eyes-test-js": () => import("./../../../src/pages/reading-the-emoji-in-the-eyes-test.js" /* webpackChunkName: "component---src-pages-reading-the-emoji-in-the-eyes-test-js" */)
}

